// src/controllers/question_controller.js
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    setTimeout(() => {
      this.element.classList.remove("table-new");
    }, 8000);
  }

  disconnect() {
  }
}
